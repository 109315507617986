import React, {useState} from "react";
import FormErrors from "../../Components/FormErrors/FormErrors";
import validator from "validator";
import HttpClient from "../../Services/HttpClient";
import Alert from "../../Components/Alert/Alert";
import Button from "../../Components/Button/Button";
import {Link} from "react-router-dom";
import Logo from "../../Images/Logo.png";
import Hero from "../../Images/Hero.png";

export default function () {
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState([]);
    const [info, setInfo] = useState(null);

    const onSubmit = async event => {
        event.preventDefault();
        setErrors([]);
        setInfo(null);
        let _errors = [];

        if (!email) _errors.push("Email er påkrævet");
        else if (!validator.isEmail(email)) _errors.push("Forkert email format");

        if (_errors.length) return setErrors(_errors);

        try {
            const data = {
                email
            };

            await HttpClient().post("/api/auth/send-verification-token", data);
            setInfo("Emailen er sendt. Tjek evt. din spam mappe");
            setEmail("");
        } catch (e) {
            setErrors([e.response.data.message]);
        }
    };

    return (
        <section style={{backgroundColor: "#1a072d"}}>
            <div style={{background: `url(${Hero})`, minHeight: "100vh"}}>
                <div className="page--50 mb-2">
                    <div className="center">
                        <img src={Logo} style={{width: "200px", margin: "40px 0"}}/>
                    </div>
                    <form onSubmit={onSubmit}>
                        {!!errors.length && <FormErrors errors={errors}/>}
                        {info && <Alert type="success">{info}</Alert>}
                        <div className="form__group mb-1">
                            <label className="form__label text--white">Din Email</label>
                            <input className="form__input text--white" value={email}
                                   onChange={e => setEmail(e.target.value)}/>
                        </div>
                        <div className="form__buttons">
                            <div className="form__buttons__left">
                                <Button className="btn--primary" type="submit">Send Kode</Button>
                            </div>
                            <div>
                                <Link to="/auth/register" className="text--white">Registrér</Link>
                                <span> | </span>
                                <Link to="/auth/login" className="text--white">Log Ind</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}
