import React from "react";

export default function () {
    return (
        <div className="page">
            <h1 className="page__title text--center">Siden Ikke Fundet</h1>

            <div className="center">Vi kunne ikke finde det, du ledte efter.</div>
        </div>
    )
}