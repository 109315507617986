import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import FormErrors from "../../Components/FormErrors/FormErrors";
import HttpClient from "../../Services/HttpClient";
import "./Login.css";
import Button from "../../Components/Button/Button";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';
import Logo from "../../Images/Logo.png";
import Hero from "../../Images/Hero.png";

export default function Login() {
    const history = useHistory();
    const [errors, setErrors] = useState([]);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const onSubmit = async event => {
        event.preventDefault();
        let _errors = [];
        setErrors([]);

        if (!email) _errors.push("Email er påkrævet");
        if (!password) _errors.push("Kodeord er påkrævet");

        if (_errors.length) {
            setErrors(_errors);
            return;
        }

        try {
            const data = {
                email,
                password
            };

            const response = await HttpClient().post("/api/auth/login", data);
            localStorage.setItem("token", response.data.token);
            window.location = "/";
        } catch (e) {
            setErrors([e.response.data.message]);
        }
    };

    const handleFacebookResponse = async facebookResponse => {
        if (facebookResponse.name) {
            const data = {
                name: facebookResponse.name,
                email: facebookResponse.email
            };

            const response = await HttpClient().post('/api/auth/login-facebook', data);
            localStorage.setItem("token", response.data.token);
            window.location = "/";
        }


    };

    const handleGoogleResponse = async googleResponse => {
        const data = {
            name: googleResponse.profileObj.name,
            email: googleResponse.profileObj.email
        };

        const response = await HttpClient().post('/api/auth/login-google', data);
        localStorage.setItem("token", response.data.token);
        window.location = "/";
    };

    return (
        <section style={{backgroundColor: "#1a072d"}}>
            <div style={{background: `url(${Hero})`, minHeight: "100vh"}}>
                <div className="page--50">
                    <div className="center">
                        <img src={Logo} style={{width: "200px", margin: "40px 0"}}/>
                    </div>
                    <form onSubmit={onSubmit}>
                        {errors.length ? <FormErrors errors={errors}/> : null}
                        <div className="form__group mb-1">
                            <label className="form__label text--white">Email</label>
                            <input className="form__input text--white"
                                   value={email}
                                   onChange={e => setEmail(e.target.value)}
                                   type="text"/>
                        </div>
                        <div className="form__group mb-1">
                            <label className="form__label text--white">Kodeord</label>
                            <input className="form__input text--white"
                                   value={password}
                                   onChange={e => setPassword(e.target.value)}
                                   type="password"/>
                        </div>

                        <div className="form__buttons">
                            <div className="form__buttons__left">
                                <Button className="btn--primary mb-1" type="submit">Log Ind</Button>
                                <Link to="/auth/register" className="btn btn--primary mb-1">Opret Bruger</Link>
                                <FacebookLogin
                                    appId="1661596470703524"
                                    callback={handleFacebookResponse}
                                    fields="name,email"
                                    isMobile={false}
                                    render={renderProps => (
                                        <Button className="btn--primary mb-1" onClick={renderProps.onClick}>
                                            <i className="fab fa-facebook-f mr-1" style={{color: 'blue'}}></i> Log Ind
                                            Med Facebook
                                        </Button>
                                    )}
                                />
                                <GoogleLogin
                                    clientId="989372703651-2kegop910g3el2i77d3k8l1quu4eh7fb.apps.googleusercontent.com"
                                    render={renderProps => (
                                        <Button className="btn--primary" onClick={renderProps.onClick}
                                                disabled={renderProps.disabled}>
                                            <i className="fab fa-google mr-1" style={{color: 'red'}}></i> Log Ind Med
                                            Google
                                        </Button>
                                    )}
                                    onSuccess={handleGoogleResponse}
                                    onFailure={handleGoogleResponse}
                                    cookiePolicy={'single_host_origin'}
                                />
                            </div>

                            <div>
                                <Link to="/auth/forgot-password" className="text--white">Glemt Kodeord?</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}
