import React, {useContext, useEffect, useState} from "react";
import Modal from "../../../../Components/Modal/Modal";
import FormErrors from "../../../../Components/FormErrors/FormErrors";
import Button from "../../../../Components/Button/Button";
import AppContext from "../../../../Contexts/AppContext";
import Autocomplete from "../../../../Components/Autocomplete/Autocomplete";
import Alert from "../../../../Components/Alert/Alert";
import HttpClient from "../../../../Services/HttpClient";

export default function ({onClose, isOpen, setCoupons}) {
    const {loading, setLoading} = useContext(AppContext);
    const [errors, setErrors] = useState([]);
    const [cities, setCities] = useState([]);
    const [title, setTitle] = useState("");
    const [active, setActive] = useState(1);
    const [canBeRedeemed, setCanBeRedeemed] = useState(1);
    const [chosenCities, setChosenCities] = useState([]);
    const [url, setUrl] = useState("");
    const [urlActive, setUrlActive] = useState(true);
    const [file, setFile] = useState("");
    const [filePreview, setFilePreview] = useState("");
    const [gotResults, setGotResults] = useState(false);
    const [citySearchTerm, setCitySearchTerm] = useState("");

    useEffect(() => {
        if (!cities.length) {
            getCities();
        }
    }, [isOpen]);

    const getCities = async () => {
        const {data} = await HttpClient().get('/api/admin/cities');
        setCities(data);
        setGotResults(true);
    };

    const handleFileChange = event => {
        let file = event.target.files[0];
        setFile(file);
        let reader = new FileReader();
        reader.onload = () => {
            let dataURL = reader.result;
            setFilePreview(dataURL);
        };
        reader.readAsDataURL(file);
    };

    const selectCity = city => {
        const exists = chosenCities.find(c => c._id === city._id);
        if (exists) {
            setCitySearchTerm("");
            return;
        }

        const _chosenCities = [...chosenCities];
        _chosenCities.push(city);
        setChosenCities(_chosenCities);
        setCitySearchTerm("");
    };

    const deselectCity = index => {
        const _chosenCities = [...chosenCities];
        _chosenCities.splice(index, 1);
        setChosenCities(_chosenCities);
    };

    const onSubmit = async event => {
        event.preventDefault();
        setErrors([]);
        let _errors = [];

        if (!title) _errors.push('Titel er påkrævet');
        if (!file) _errors.push("Billede er påkrævet");
        if (!chosenCities.length) _errors.push('Mindst én by er påkrævet');

        if (_errors.length) return setErrors(_errors);

        try {
            const formData = new FormData();
            formData.append("title", title);
            formData.append("active", active ? "true" : "false");
            formData.append("canBeRedeemed", active ? "true" : "false");
            formData.append("chosenCities", JSON.stringify(chosenCities));
            formData.append("url", url);
            formData.append("urlActive", urlActive ? "true" : "false");
            formData.append("image", file);
            const {data} = await HttpClient().post("/api/admin/coupons/create", formData);
            setCoupons(prevState => {
                return [...prevState, {...data, cities: chosenCities}];
            });
            setFile("");
            setFilePreview("");
            setTitle("");
            setUrl("");
            onClose();

        } catch (e) {
            setErrors([e.response.data.message]);
        }
    };

    return (
        <Modal onClose={onClose} isOpen={isOpen} contentHeight="700px">
            <h1 className="mb-1">Opret Kupon</h1>
            <form onSubmit={onSubmit}>
                {!!errors.length && <FormErrors errors={errors}/>}
                {gotResults && cities.length === 0 && (
                    <Alert type="error">Du skal tilføje en by, før du fortsætter</Alert>
                )}
                <div className="form__group mb-1">
                    <label className="form__label">Titel</label>
                    <input className="form__input" value={title} onChange={e => setTitle(e.target.value)}/>
                </div>
                <div className="form__group mb-1">
                    <label className="form__label">Er kuponnen aktiv?</label>
                    <select value={active} onChange={e => setActive(parseInt(e.target.value))}>
                        <option value={1}>Ja</option>
                        <option value={0}>Nej</option>
                    </select>
                </div>
                <div className="form__group mb-1">
                    <label className="form__label">Kan kuponen indløses?</label>
                    <select value={canBeRedeemed} onChange={e => setCanBeRedeemed(parseInt(e.target.value))}>
                        <option value={1}>Ja</option>
                        <option value={0}>Nej</option>
                    </select>
                </div>
                <div className="form__group mb-1">
                    <label className="form__label">Vælg Billede</label>
                    <input type="file" className="form__input" onChange={handleFileChange}/>
                </div>
                {filePreview && <img src={filePreview} className="form__input mb-1"/>}
                <div className="form__group mb-1">
                    <label className="form__label">Byer</label>
                    {!!chosenCities.length && <div className="mb-1 flex flex--wrap">
                        {chosenCities.map((city, index) => (
                            <div className="badge" key={index}>
                                <span className="badge__close" onClick={() => deselectCity(index)}>&times;</span>
                                <span className="badge__content">{city.name}</span>
                            </div>
                        ))}
                    </div>}
                    <Autocomplete items={cities}
                                  searchTerm={citySearchTerm}
                                  setSearchTerm={setCitySearchTerm}
                                  label="name"
                                  placeholder="Vælg By"
                                  onItemSelected={item => selectCity(item)}/>
                </div>
                <div className="form__group mb-1">
                    <label className="form__label">URL</label>
                    <input className="form__input" value={url} onChange={e => setUrl(e.target.value)}/>
                </div>
                <div className="form__group mb-1">
                    <label className="form__label">Er URL aktiv?</label>
                    <select value={urlActive} onChange={e => setUrlActive(parseInt(e.target.value))}>
                        <option value={1}>Ja</option>
                        <option value={0}>Nej</option>
                    </select>
                </div>
                <Button className="btn--primary"
                        loading={loading}
                        type="submit"
                        disabled={loading || (gotResults && cities.length === 0) || !gotResults}>Opret</Button>
            </form>
        </Modal>
    )
}
