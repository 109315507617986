import React, {useEffect, useState} from "react";
import Modal from "../../../../Components/Modal/Modal";
import Button from "../../../../Components/Button/Button";
import Autocomplete from "../../../../Components/Autocomplete/Autocomplete";
import HttpClient from "../../../../Services/HttpClient";
import FormErrors from "../../../../Components/FormErrors/FormErrors";

export default function ({isOpen, onClose}) {
    const [chosenCities, setChosenCities] = useState([]);
    const [citySearchTerm, setCitySearchTerm] = useState("");
    const [cities, setCities] = useState([]);
    const [title, setTitle] = useState("");
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (!cities.length) {
            getCities();
        }
    }, [isOpen]);

    const getCities = async () => {
        const {data} = await HttpClient().get('/api/admin/cities');
        setCities(data);
    };

    const deselectCity = index => {
        const _chosenCities = [...chosenCities];
        _chosenCities.splice(index, 1);
        setChosenCities(_chosenCities);
    };

    const selectCity = city => {
        const exists = chosenCities.find(c => c._id === city._id);
        if (exists) {
            setCitySearchTerm("");
            return;
        }

        const _chosenCities = [...chosenCities];
        _chosenCities.push(city);
        setChosenCities(_chosenCities);
        setCitySearchTerm("");
    };

    const onSubmit = async event => {
        event.preventDefault();
        setErrors([]);
        let _errors = [];

        if (!title) _errors.push('Titel er påkrævet');
        if (!chosenCities.length) _errors.push('Mindst én by er påkrævet');

        const data = {
            title,
            chosenCities
        };

        await HttpClient().post("/api/admin/coupons/send-push", data);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <h1 className="page__title text--center mb-1">Opret Ny Pushbesked</h1>
            <form onSubmit={onSubmit}>
                {!!errors.length && <FormErrors errors={errors}/>}
                <div className="form__group mb-1">
                    <label className="form__label">Titel</label>
                    <input className="form__input" value={title} onChange={e => setTitle(e.target.value)}/>
                </div>
                <div className="form__group mb-1">
                    <label className="form__label">Byer</label>
                    {!!chosenCities.length && <div className="mb-1 flex flex--wrap">
                        {chosenCities.map((city, index) => (
                            <div className="badge" key={index}>
                                <span className="badge__close" onClick={() => deselectCity(index)}>&times;</span>
                                <span className="badge__content">{city.name}</span>
                            </div>
                        ))}
                    </div>}
                    <Autocomplete items={cities}
                                  searchTerm={citySearchTerm}
                                  setSearchTerm={setCitySearchTerm}
                                  label="name"
                                  placeholder="Vælg By"
                                  onItemSelected={item => selectCity(item)}/>
                </div>
                <Button className="btn--success" type="submit">Send</Button>
            </form>
        </Modal>
    )
}