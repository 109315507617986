import {createContext} from "react";

const AppContext = createContext({
    installed: false,
    setInstalled: () => {
    },
    user: null,
    setUser: () => {
    },
    logout: () => {
    },
    loading: false,
    setLoading: () => {
    },
});

export default AppContext;
