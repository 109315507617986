import Config from "./Config/Config";

const pushServerPublicKey = Config.VAPID_PUBLIC_KEY;
const convertedKey = convertKey(pushServerPublicKey);

function convertKey(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4)
    const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}

function isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
    return await Notification.requestPermission();
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
    const serviceWorker = await navigator.serviceWorker.ready;
    return await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertKey()
    });
}

function getUserSubscription() {
    return navigator.serviceWorker.ready
        .then(function (serviceWorker) {
            return serviceWorker.pushManager.getSubscription();
        })
        .then(function (pushSubscription) {
            console.log(pushSubscription);
            return pushSubscription;
        });
}


export {
    isPushNotificationSupported,
    askUserPermission,
    getUserSubscription,
    createNotificationSubscription,
    convertKey
};