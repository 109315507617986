import React, {useContext, useEffect, useState} from "react";
import AppContext from "../../../Contexts/AppContext";
import {useHistory} from 'react-router-dom';
import Button from "../../../Components/Button/Button";
import CreateCity from "./Create/CreateCity";
import HttpClient from "../../../Services/HttpClient";
import EditCity from "./Edit/EditCity";

export default function () {
    const history = useHistory();
    const {user} = useContext(AppContext);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [cities, setCities] = useState([]);
    const [activeCity, setActiveCity] = useState(null);

    useEffect(() => {
        if (user.role !== 'admin') {
            history.push('/errors/401');
        } else {
            getCities();
        }
    }, []);

    const getCities = async () => {
        const {data} = await HttpClient().get('/api/admin/cities');
        setCities(data);
    };

    const selectCityForEdit = city => {
        setActiveCity(city);
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setActiveCity(null);
        setEditModalOpen(false);
    };


    const activateCity = async (cityId, index) => {
        await HttpClient().post('/api/admin/cities/activate', {id: cityId});
        const _cities = [...cities];
        _cities[index].active = true;
        setCities(_cities);
    };

    const deactivateCity = async (cityId, index) => {
        await HttpClient().post('/api/admin/cities/deactivate', {id: cityId});
        const _cities = [...cities];
        _cities[index].active = false;
        setCities(_cities);
    };

    const deleteCity = async (cityId, index) => {
        const data = {
            id: cityId
        };

        await HttpClient().post("/api/admin/cities/delete", data);
        setCities(prevState => {
            const _cities = [...prevState];
            _cities.splice(index, 1);
            return _cities;
        })
    };

    return (
        <div className="page">
            <h1 className="page__title">Byer</h1>
            <Button className="btn--primary mb-1" onClick={() => setCreateModalOpen(true)}>Opret By</Button>
            <table className="table">
                <thead>
                <tr>
                    <td style={{width: "50%"}}>Navn</td>
                    <td style={{width: "10%"}}>Er Aktiv?</td>
                    <td style={{width: "10%"}} className="only-desktop">Kuponer</td>
                    <td style={{width: "30%"}} className="only-desktop">Handlinger</td>
                </tr>
                </thead>
                <tbody>
                {cities.map((city, index) => (
                    <tr key={index}>
                        <td>{city.name}</td>
                        <td>{city.active ? "Ja" : "Nej"}</td>
                        <td className="only-desktop">{city.couponCount}</td>
                        <td className="only-desktop">
                            {city.active
                                ?
                                <Button className="btn--primary btn--small mr-1"
                                        onClick={() => deactivateCity(city._id, index)}>
                                    <i className="fas fa-times"/>
                                </Button>
                                :
                                <Button className="btn--primary btn--small mr-1"
                                        onClick={() => activateCity(city._id, index)}>
                                    <i className="fas fa-check"/>
                                </Button>
                            }
                            <Button className="btn--primary btn--small mr-1" onClick={() => selectCityForEdit(city)}><i
                                className="fas fa-edit"/></Button>
                            <Button className="btn--primary btn--small"
                                    onClick={() => deleteCity(city._id, index)}>
                                <i className="fas fa-trash"/>
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            {activeCity && (
                <EditCity isOpen={isEditModalOpen} onClose={closeEditModal} setCities={setCities} city={activeCity}/>
            )}
            <CreateCity isOpen={isCreateModalOpen} onClose={() => setCreateModalOpen(false)} setCities={setCities}/>
        </div>
    )
}
