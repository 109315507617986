import React, { useContext, useEffect, useState } from 'react';
import './Profile.css';
import AppContext from '../../Contexts/AppContext';
import ChangePassword from './ChangePassword/ChangePassword';
import ChangeEmail from './ChangeEmail/ChangeEmail';
import Button from '../../Components/Button/Button';
import HttpClient from '../../Services/HttpClient';

export default function Profile() {
  const { user, logout } = useContext(AppContext);
  const [isChangePasswordOpen, setChangePasswordOpen] = useState(false);
  const [isEmailOpen, setEmailOpen] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });
  }, []);

  const handleAddToHomeScreenClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    }
  };

  async function deleteUser() {
    let confirmed = window.confirm('Er du sikker?');
    if (!confirmed) return;

    await HttpClient().post('/api/user/delete-user', {});
    logout();
    window.location = '/';
  }

  return (
    <div className="page">
      <h1 className="page__title text--center">Profil</h1>

      <section className="profile__buttons">
        {user.password && (
          <Button
            className="btn--primary mr-1 mb-1"
            onClick={() => setChangePasswordOpen(true)}
          >
            Skift Kodeord
          </Button>
        )}
        <Button
          className="btn--primary mr-1 mb-1"
          onClick={() => setEmailOpen(true)}
        >
          Skift Email
        </Button>
        {deferredPrompt && (
          <Button className="btn--primary" onClick={handleAddToHomeScreenClick}>
            Føj Appen Til Hjemmeskærm
          </Button>
        )}
      </section>

      <section className="mt-2 flex flex--justify-center">
        <Button className="btn--error" onClick={() => deleteUser()}>
          Slet Bruger
        </Button>
      </section>

      <ChangePassword
        isOpen={isChangePasswordOpen}
        onClose={() => setChangePasswordOpen(false)}
      />
      <ChangeEmail isOpen={isEmailOpen} onClose={() => setEmailOpen(false)} />
    </div>
  );
}
