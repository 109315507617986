import React, {useEffect, useState} from "react";
import {withRouter, useHistory, Link} from "react-router-dom";
import Alert from "../../Components/Alert/Alert";
import queryString from "query-string";
import HttpClient from "../../Services/HttpClient";
import FormErrors from "../../Components/FormErrors/FormErrors";
import Button from "../../Components/Button/Button";
import Logo from "../../Images/Logo.png";
import Hero from "../../Images/Hero.png";

const ResetPassword = ({location}) => {
    const {token} = queryString.parse(location.search);
    const history = useHistory();
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        setError(null);
        setUser(null);

        if (token) {
            checkToken();
        }
    }, [token]);

    const checkToken = async () => {
        try {
            const data = {
                token
            };

            const response = await HttpClient().post("/api/auth/check-email-verification-token", data);
            setUser(response.data);
        } catch (e) {
            setError(e.response.data.message);
        }
    };

    const onSubmit = async event => {
        event.preventDefault();
        setErrors([]);
        let _errors = [];

        if (!password) _errors.push("Kodeord er påkrævet");
        if (!passwordAgain) _errors.push("Kodeord bekræftelse er påkrævet");
        else if (password !== passwordAgain) _errors.push("Kodeord er ikke ens");

        if (_errors.length) return setErrors(_errors);

        const data = {
            userId: user._id,
            password
        };

        await HttpClient().post("/api/auth/reset-password", data);
        history.push("/auth/login");
    };

    return (
        <section style={{backgroundColor: "#1a072d"}}>
            <div style={{background: `url(${Hero})`, minHeight: "100vh"}}>
                <div className="page--50 mb-2">
                    <div className="center">
                        <img src={Logo} style={{width: "200px", margin: "40px 0"}}/>
                    </div>
                    {user && (
                        <div>
                            <h2 className="text--white">Velkommen tilbage, {user.name}</h2>
                            <form onSubmit={onSubmit}>
                                {!!errors.length && <FormErrors errors={errors}/>}
                                <div className="form__group mb-1">
                                    <label className="form__label text--white">Nyt Kodeord</label>
                                    <input className="form__input text--white"
                                           value={password}
                                           type="password"
                                           onChange={e => setPassword(e.target.value)}/>
                                </div>
                                <div className="form__group mb-1">
                                    <label className="form__label text--white">Kodeord Igen</label>
                                    <input className="form__input text--white"
                                           value={passwordAgain}
                                           type="password"
                                           onChange={e => setPasswordAgain(e.target.value)}/>
                                </div>
                                <Button className="btn--primary" type="submit">Nulstil</Button>
                            </form>
                        </div>
                    )}
                    {error && (
                        <Alert type="error">{error}</Alert>
                    )}
                </div>
            </div>
        </section>
    )
};

export default withRouter(ResetPassword);
