import React, {useContext, useEffect, useState} from "react";
import Modal from "../Modal/Modal";
import HttpClient from "../../Services/HttpClient";
import AppContext from "../../Contexts/AppContext";

import {
    askUserPermission,
    getUserSubscription,
    isPushNotificationSupported,
    createNotificationSubscription, convertKey,
} from "../../push-notifications";
import Config from "../../Config/Config";

const isPushSupported = isPushNotificationSupported();
console.log(`Push supported: ${isPushSupported}`);

export default function ({isOpen, onClose}) {
    const {setUser, user} = useContext(AppContext);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        if (!cities.length) {
            getCities();
        }
    }, [isOpen]);

    const getCities = async () => {
        const {data} = await HttpClient().get("/api/cities");
        setCities(data);
    };

    const changeCity = async city => {
        const data = {
            cityId: city._id
        };


        await HttpClient().post("/api/user/change-city", data);
        setUser(prevState => {
            return {...prevState, activeCity: city};
        });
        onClose();


    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <div className="pa-1">
                {cities && (
                    <section className="list">
                        {cities.map((city, index) => (
                            <li className="list__item" key={index}>
                                <a href="#" className="list__link flex flex--justify-between flex--align-center"
                                   onClick={() => changeCity(city)}>

                                    {city.name}
                                    {user.activeCity && city._id === user.activeCity._id &&
                                    <i className="far fa-check-circle text--red" style={{fontSize: '20px'}}/>}

                                </a>
                            </li>
                        ))}
                    </section>
                )}
            </div>
        </Modal>
    )
}
