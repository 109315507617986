import React, {useContext, useEffect, useState} from "react";
import AppContext from "../../../Contexts/AppContext";
import {useHistory} from 'react-router-dom';
import Button from "../../../Components/Button/Button";
import HttpClient from "../../../Services/HttpClient";
import moment from "moment";
import ReactPaginate from 'react-paginate';

export default function () {
    const history = useHistory();
    const {user} = useContext(AppContext);
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(null);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        if (user.role !== 'admin') {
            history.push('/errors/401');
        } else {
            getUsers(0);
        }
    }, []);

    const getUsers = async skip => {
        const {data} = await HttpClient().get('/api/admin/users?skip=' + skip);
        setUsers(data.users);
        setTotal(data.total);
        setPageCount(Math.ceil(data.total / 10));
    };

    const exportUsers = () => {
        const token = localStorage.getItem("token");
        window.open("/api/export/users?token=" + token);
    };

    const handlePageClick = async data => {
        const selected = data.selected;
        let skip = Math.ceil(selected * 10);
        await getUsers(skip);
    };

    return (
        <div className="page">
            <h1 className="page__title">Brugere</h1>
            <Button className="btn--primary mb-1"
                    onClick={exportUsers}>
                Eksportér Til CSV
            </Button>
            <p className="mb-1">Total antal brugere: {total}</p>
            <table className="table mb-1">
                <thead>
                <tr>
                    <td style={{width: "50%"}}>Navn</td>
                    <td style={{width: "50%"}}>Email</td>
                    <td style={{width: "50%"}} className="only-desktop">By</td>
                    <td style={{width: "50%"}} className="only-desktop">Registreringsdato</td>
                </tr>
                </thead>
                <tbody>
                {users.map((user, index) => (
                    <tr key={index}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td className="only-desktop">{user.activeCity ? user.activeCity.name : "N/A"}</td>
                        <td className="only-desktop">{moment(user.createdAt).format("DD-MM-YYYY")}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
                previousLabel="Forrige"
                nextLabel="Næste"
            />
        </div>
    )
}
