import React, {useContext, useEffect, useState} from "react";
import Modal from "../Modal/Modal";
import HttpClient from "../../Services/HttpClient";
import AppContext from "../../Contexts/AppContext";
import {isIphone} from "../../Helpers/Navigator";

export default function ({isOpen, onClose}) {
    const {setUser} = useContext(AppContext);
    const [isBtnShown, setBtnShown] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('beforeinstallprompt', (e) => {
                e.preventDefault();
                setDeferredPrompt(e);
                setBtnShown(true);
            })
            update();
        }
    }, [isOpen])

    const handleBtnClick = () => {
        // hide our user interface that shows our A2HS button
        setBtnShown(false);
        if (deferredPrompt) {
            // Show the prompt
            deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            deferredPrompt.userChoice.then((choiceResult) => {
                setDeferredPrompt(null);
                onClose();
            });
        }
    }

    const update = async () => {
        await HttpClient().post("/api/user/update-add-to-home-screen");
        setUser(prevState => {
            return {...prevState, hasSeenAddToHomeScreenModal: true};
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <section className="pa-1 text--center">
                <p>
                    Husk at tilføje vores app til din hjemmeskærm.
                </p>

                {isBtnShown && (
                    <button className="btn btn--primary" onClick={() => handleBtnClick()}>Føj til hjemmeskærm</button>

                )}
            </section>
        </Modal>
    )
}
