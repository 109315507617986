import React from "react";

export default function () {
    return (
        <div className="page">
            <h1 className="page__title text--center">Du har ikke adgang</h1>

            <div className="center">Du ser denne side, fordi du ikke har adgang.</div>
        </div>
    )
}