import React, {useState} from "react";
import Modal from "../Modal/Modal";
import {
    EmailShareButton,
    FacebookShareButton,
} from "react-share";
import "./ShareModal.css";
import Button from "../Button/Button";

export default function ({isOpen, onClose}) {
    const url = "https://mobil.vaerdicheck.dk";
    const copyToClipboard = () => {
        const el = document.getElementById("copy");
        el.select();
        document.execCommand('copy');
    };

    return (
        <Modal onClose={onClose} isOpen={isOpen}>
            <section>
                <h1 className="page__title">Del Appen</h1>

                <div>
                    <section className="share__buttons mb-1">
                        <EmailShareButton url={url}>
                        <span className="btn btn--primary">
                            <i className="fas fa-envelope"/>
                        </span>
                        </EmailShareButton>
                        <FacebookShareButton url={url}>
                        <span className="btn btn--primary">
                            <i className="fab fa-facebook-f"/>
                        </span>
                        </FacebookShareButton>
                    </section>
                    <section className="flex flex--justify-center flex--align-center">
                        <form onSubmit={e => e.preventDefault()}
                              className="flex flex--column flex--align-center flex--justify-center w-100">
                            <input className="form__input mb-1"
                                   id="copy"
                                   readOnly
                                   placeholder="URL"
                                   value={url}/>
                            <Button className="btn--primary" onClick={copyToClipboard}>
                                Kopiér Link
                            </Button>
                        </form>

                    </section>

                </div>
            </section>
        </Modal>
    )
}
