import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import FormErrors from "../../Components/FormErrors/FormErrors";
import validator from "validator";
import HttpClient from "../../Services/HttpClient";
import Button from "../../Components/Button/Button";
import Logo from "../../Images/Logo.png";
import Hero from "../../Images/Hero.png";

export default function Register() {
    const history = useHistory();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [errors, setErrors] = useState([]);
    const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);

    const onSubmit = async event => {
        event.preventDefault();
        let _errors = [];
        setErrors([]);

        if (!name) _errors.push("Navn er påkrævet");
        if (!email) _errors.push("Email er påkrævet");
        else if (!validator.isEmail(email)) _errors.push("Email skal have korrekt format");
        if (!password) _errors.push("Kodeord er påkrævet");
        if (!passwordAgain) _errors.push("Kodeord bekræftelse er påkrævet");
        else if (password !== passwordAgain) _errors.push("Kodeord skal være ens");
        if (!acceptedPrivacyPolicy) _errors.push('Du skal acceptere vores privatlivspolitik');
        if (_errors.length) return setErrors(_errors);

        try {
            const data = {
                name,
                email,
                password
            };

            const response = await HttpClient().post("/api/auth/register", data);
            localStorage.setItem("token", response.data.token);
            window.location = '/';
        } catch (e) {
            setErrors([e.response.data.message]);
        }

    };

    return (
        <section style={{backgroundColor: "#1a072d"}}>
            <div style={{background: `url(${Hero})`, minHeight: "100vh"}}>
                <div className="page--50 mb-2">
                    <div className="center">
                        <img src={Logo} style={{width: "200px", margin: "40px 0"}}/>
                    </div>
                    <form onSubmit={onSubmit}>
                        {!!errors.length && <FormErrors errors={errors}/>}
                        <div className="form__group mb-1">
                            <label className="form__label text--white">Navn</label>
                            <input className="form__input text--white" value={name}
                                   onChange={e => setName(e.target.value)}/>
                        </div>
                        <div className="form__group mb-1">
                            <label className="form__label text--white">Email</label>
                            <input className="form__input text--white" value={email}
                                   onChange={e => setEmail(e.target.value)}/>
                        </div>
                        <div className="form__group mb-1">
                            <label className="form__label text--white">Kodeord</label>
                            <input className="form__input text--white"
                                   type="password"
                                   value={password}
                                   onChange={e => setPassword(e.target.value)}/>
                        </div>
                        <div className="form__group mb-1">
                            <label className="form__label text--white">Kodeord Igen</label>
                            <input className="form__input text--white"
                                   type="password"
                                   value={passwordAgain}
                                   onChange={e => setPasswordAgain(e.target.value)}/>
                        </div>
                        <div className="form__group mb-1 flex flex--align-center">
                            <input className="mr-1"
                                   type="checkbox"
                                   value={acceptedPrivacyPolicy}
                                   onChange={e => setAcceptedPrivacyPolicy(e.target.checked)}/>
                            <label className="form__label text--white">Jeg godkender cookie og
                                privatlivspolitik (<a className="text--white"
                                                      href="http://vaerdicheck.dk/Privatlivspolitik-V%C3%A6rdicheck-ApS"
                                                      target="_blank">læs her</a>)</label>

                        </div>
                        <div className="form__buttons">
                            <div className="form__buttons__left">
                                <Button className="btn--primary" type="submit">Opret Bruger</Button>
                            </div>
                            <div>
                                <Link to="/auth/forgot-password" className="text--white">Glemt Kodeord?</Link>
                                <span> | </span>
                                <Link to="/auth/login" className="text--white">Log Ind</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}
