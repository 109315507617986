import React, {useContext, useEffect, useState} from "react";
import Modal from "../../../../Components/Modal/Modal";
import FormErrors from "../../../../Components/FormErrors/FormErrors";
import Button from "../../../../Components/Button/Button";
import AppContext from "../../../../Contexts/AppContext";
import Autocomplete from "../../../../Components/Autocomplete/Autocomplete";
import Alert from "../../../../Components/Alert/Alert";
import HttpClient from "../../../../Services/HttpClient";

export default function ({onClose, isOpen, setCities}) {
    const {loading, setLoading} = useContext(AppContext);
    const [errors, setErrors] = useState([]);
    const [name, setName] = useState("");
    const [active, setActive] = useState(1);

    const onSubmit = async event => {
        event.preventDefault();
        setErrors([]);

        if (!name) return setErrors(["Navn er påkrævet"]);

        const data = {
            name,
            active
        };

        const response = await HttpClient().post('/api/admin/cities/create', data);
        setCities(prevState => {
            return [...prevState, {...response.data, couponCount: 0}];
        });
        setName("");
        setActive(1);
        onClose();
    };

    return (
        <Modal onClose={onClose} isOpen={isOpen}>
            <h1 className="mb-1">Opret By</h1>
            <form onSubmit={onSubmit}>
                {!!errors.length && <FormErrors errors={errors}/>}
                <div className="form__group mb-1">
                    <label className="form__label">Navn</label>
                    <input className="form__input" value={name} onChange={e => setName(e.target.value)}/>
                </div>
                <div className="form__group mb-1">
                    <label className="form__label">Er byen aktiv?</label>
                    <select value={active} onChange={e => setActive(parseInt(e.target.value))}>
                        <option value={1}>Ja</option>
                        <option value={0}>Nej</option>
                    </select>
                </div>
                <Button className="btn--primary"
                        type="submit"
                        loading={loading}
                        disabled={loading}>Opret</Button>
            </form>
        </Modal>
    )
}
