import React, {useContext} from "react";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import AppContext from "../../Contexts/AppContext";
import HttpClient from "../../Services/HttpClient";
import {confirmAlert} from "react-confirm-alert";
import "./CouponModal.css";

export default function ({isOpen, onClose, coupon, setCoupons, deleteOnUnfavorite}) {
    const {user} = useContext(AppContext);
    const makeFavorite = async () => {
        const data = {
            couponId: coupon._id,
            cityId: user.activeCity._id
        };

        await HttpClient().post("/api/coupons/favorite", data);
        setCoupons(prevState => {
            const _coupons = [...prevState];
            const index = _coupons.findIndex(c => c._id === coupon._id);
            _coupons[index].favorite = true;
            return _coupons;
        });
        onClose();
    };

    const unfavorite = async () => {
        const data = {
            couponId: coupon._id,
            cityId: user.activeCity._id
        };

        await HttpClient().post("/api/coupons/unfavorite", data);
        setCoupons(prevState => {
            const _coupons = [...prevState];
            const index = _coupons.findIndex(c => c._id === coupon._id);
            if (deleteOnUnfavorite) {
                _coupons.splice(index, 1);
            } else {
                _coupons[index].favorite = false;
            }
            return _coupons;
        });
        onClose();
    };

    const redeem = async () => {
        const data = {
            couponId: coupon._id,
            cityId: user.activeCity._id
        };

        const response = await HttpClient().post("/api/coupons/redeem", data);
        setCoupons(prevState => {
            const _coupons = [...prevState];
            const index = _coupons.findIndex(c => c._id === coupon._id);
            _coupons[index].redeemed = true;
            _coupons[index].redeemedNumber = response.data.number;
            return _coupons;
        });
        onClose();
    };

    const visitWebsite = () => {
        window.open(coupon.url, "_blank");
    };

    const confirmRedeem = () => {
        onClose();
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div>
                        <h1 className="text--center">Er du sikker på du vil indløse tilbuddet?</h1>
                        <div className="center">
                            <Button className="btn--error mr-1" onClick={onClose}>Nej</Button>
                            <Button className="btn--success"
                                    onClick={async () => {
                                        await redeem();
                                        onClose();
                                    }}
                            >
                                Ja
                            </Button>
                        </div>
                    </div>
                );
            }
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <section className="pa-1 flex flex--justify-center flex--column">
                <img className="w-100 mb-1" src={"/api" + coupon.imagePath} alt="kupon"/>
                <div className="coupon__buttons">
                    {!coupon.favorite ? (
                        <Button className="btn--primary coupon__button--big" onClick={makeFavorite}>
                            <i className="fas fa-heart text--red"></i> Tilføj Til Favoritter
                        </Button>
                    ) : (
                        <Button className="btn--error coupon__button--big" onClick={unfavorite}>Fjern Fra
                            Favoritter</Button>
                    )}
                    {!coupon.redeemed ? (
                        <Button className="btn--success coupon__button--big" onClick={confirmRedeem}
                                disabled={!coupon.canBeRedeemed}>
                            {coupon.canBeRedeemed ? "Indløs Tilbud" : "Kan ikke indløses"}
                        </Button>
                    ) : (
                        <div>
                            Tilbuddet Indløst
                        </div>
                    )}
                    {coupon.urlActive && (
                        <Button className="btn--primary coupon__button--big" onClick={visitWebsite}>
                            <i className="fas fa-window-maximize"></i> Besøg Hjemmeside
                        </Button>
                    )}
                </div>


            </section>
            <div className="center mt-1 text--center">
                <span className="coupon__text-redeem">Indløses sammen med personalet i butikken</span>
            </div>
        </Modal>
    )
}
