import React, {useContext, useEffect, useState} from "react";
import AppContext from "../../Contexts/AppContext";
import HttpClient from "../../Services/HttpClient";
import CouponModal from "../../Components/CouponModal/CouponModal";
import moment from "moment";
import debounce from "lodash.debounce";

export default function () {
    const {user} = useContext(AppContext);
    const [coupons, setCoupons] = useState([]);
    const [coupon, setCoupon] = useState(null);
    const [isCouponModalOpen, setCouponModalOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        if (user.activeCity) {
            getCoupons(0);
        }
    }, [user]);

    useEffect(() => {
        const pageContainer = document.getElementById("page-container");
        let handler = debounce(() => {
            if (
                pageContainer.offsetHeight + pageContainer.scrollTop
                === pageContainer.scrollHeight
            ) {
                getCoupons(coupons.length);

            }
        }, 300);
        if (hasMore) {
            pageContainer.addEventListener("scroll", handler);
        }

        return () => {
            pageContainer.removeEventListener("scroll", handler);
        }
    }, [hasMore, coupons]);

    const getCoupons = async skip => {
        const {data} = await HttpClient().get("/api/coupons/favorites?cityId=" + user.activeCity._id + "&skip=" + skip);
        if (data.length) {
            if (skip === 0) {
                setCoupons(data);
            } else {
                setCoupons(prevState => {
                    return [...prevState, ...data];
                });
            }
            setHasMore(true);


        } else {
            setHasMore(false);
            if (skip === 0) {
                setCoupons([]);
            }
        }
    };

    const closeCouponModal = () => {
        setCoupon(null);
        setCouponModalOpen(false);
    };

    const selectCoupon = _coupon => {
        setCoupon(_coupon);
        setCouponModalOpen(true);
    };

    const unfavorite = async (coupon) => {
        const data = {
            couponId: coupon._id,
            cityId: user.activeCity._id
        };

        await HttpClient().post("/api/coupons/unfavorite", data);
        setCoupons(prevState => {
            const _coupons = [...prevState];
            const index = _coupons.findIndex(c => c._id === coupon._id);
            _coupons.splice(index, 1);
            return _coupons;
        });
    };

    return (
        <div className="page page--50" id="page-container">
            <div className="coupons">
                {coupons.map((coupon, index) => (
                    <div className="coupon" key={index}>
                        {coupon.redeemed && (
                            <div className="coupon__redeemed">
                                <label className="coupon__redeemed-label">Indløst</label>
                                <span className="coupon__redeemed-code">Kode: #{coupon.redeemedNumber}</span>
                                <span
                                    className="coupon__redeemed-date">{moment(coupon.redeemedDate).format("DD-MM-YYYY HH:mm")}</span>
                            </div>
                        )}
                        {coupon.favorite &&
                        <i className="coupon__favorite fas fa-heart" onClick={() => unfavorite(coupon)}/>}
                        <img className="coupon__img" src={"/api" + coupon.imagePath} alt="kupon"
                             onClick={() => selectCoupon(coupon)}/>
                    </div>
                ))}
            </div>
            {coupon && <CouponModal isOpen={isCouponModalOpen}
                                    coupon={coupon}
                                    onClose={closeCouponModal}
                                    deleteOnUnfavorite
                                    setCoupons={setCoupons}/>}
        </div>
    )
}
