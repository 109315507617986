import React, {useState, useContext, useEffect} from "react";
import AppContext from "../../../Contexts/AppContext";
import "./ChangeEmail.css";
import Modal from "../../../Components/Modal/Modal";
import FormErrors from "../../../Components/FormErrors/FormErrors";
import HttpClient from "../../../Services/HttpClient";
import validator from "validator";
import Button from "../../../Components/Button/Button";

export default function ChangeEmail(props) {
    const {user, setUser} = useContext(AppContext);
    const [isModalOpen, setModalOpen] = useState(false);
    const [errors, setErrors] = useState([]);
    const [email, setEmail] = useState(user.email);

    const onSubmit = async e => {
        e.preventDefault();
        let _errors = [];
        setErrors([]);

        if (!email) _errors.push("Email er påkrævet");
        else if (email === user.email) _errors.push("Du kan ikke bruge din nuværende email");
        else if (!validator.isEmail(email)) _errors.push("Forkert email format");

        if (_errors.length) {
            setErrors(_errors);
            return;
        }

        try {
            const data = {
                userId: user._id,
                email
            };

            await HttpClient().post("/api/user/change-email", data);
            onClose();
            setUser({
                ...user,
                email
            });
        } catch (e) {
            setErrors([e.response.data.message]);
        }
    };

    useEffect(() => {
        setModalOpen(props.isOpen);
    }, [props.isOpen]);

    const onClose = () => {
        props.onClose();
    };

    return (
        <div className="change-email">
            <Modal isOpen={isModalOpen}
                   onClose={onClose}>
                <h2 className="change-email__title">Skift Email</h2>
                <hr className="line"/>
                <form className="form" onSubmit={onSubmit}>
                    {!!errors.length && <FormErrors errors={errors}/>}
                    <div className="form__group mb-1">
                        <label className="form__label">Ny Email</label>
                        <input className="form__input"
                               onChange={e => setEmail(e.target.value)}
                               type="text"
                               value={email}/>
                    </div>
                    <Button className="btn--success" type="submit">Skift</Button>
                </form>
            </Modal>
        </div>
    )
}
