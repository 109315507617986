import React, {useContext, useEffect, useState} from "react";
import "./Coupons.css";
import AppContext from "../../../Contexts/AppContext";
import {useHistory} from 'react-router-dom';
import Button from "../../../Components/Button/Button";
import CreateCoupon from "./Create/CreateCoupon";
import HttpClient from "../../../Services/HttpClient";
import EditCoupon from "./Edit/EditCoupon";
import ReactPaginate from 'react-paginate';
import Autocomplete from "../../../Components/Autocomplete/Autocomplete";
import Push from "./Push/Push";

export default function () {
    const history = useHistory();
    const {user} = useContext(AppContext);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isPushModalOpen, setPushModalOpen] = useState(false);
    const [coupons, setCoupons] = useState([]);
    const [activeCoupon, setActiveCoupon] = useState(null);
    const [total, setTotal] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [redeemedCount, setRedeemedCount] = useState("Indlæser...");
    const [sort, setSort] = useState("name");
    const [isSortCity, setIsSortCity] = useState(false);
    const [citySearchTerm, setSitySearchTerm] = useState("");


    useEffect(() => {
        if (user.role !== 'admin') {
            history.push('/errors/401');
        } else {
            getCoupons(0);
            getRedeemedCount();
        }
    }, []);

    const getRedeemedCount = async () => {
        const {data} = await HttpClient().get('/api/coupons/get-redeemed-count');
        setRedeemedCount(data.redeemedCount);
    };

    const getCoupons = async (skip, city) => {
        let url = '/api/admin/coupons?skip=' + skip;
        if (city) {
            url += '&cityId=' + city._id;
        }

        const {data} = await HttpClient().get(url);
        setCoupons(data.coupons);
        setTotal(data.total);
        setPageCount(Math.ceil(data.total / 10));
    };

    const selectCouponForEdit = coupon => {
        setActiveCoupon(coupon);
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setActiveCoupon(null);
        setEditModalOpen(false);
    };

    const activateCoupon = async (couponId, index) => {
        await HttpClient().post('/api/admin/coupons/activate', {id: couponId});
        const _coupons = [...coupons];
        _coupons[index].active = true;
        setCoupons(_coupons);
    };

    const deactivateCoupon = async (couponId, index) => {
        await HttpClient().post('/api/admin/coupons/deactivate', {id: couponId});
        const _coupons = [...coupons];
        _coupons[index].active = false;
        setCoupons(_coupons);
    };

    const deleteCoupon = async (couponId, index) => {
        const data = {
            id: couponId
        };

        await HttpClient().post("/api/admin/coupons/delete", data);
        setCoupons(prevState => {
            const _coupons = [...prevState];
            _coupons.splice(index, 1);
            return _coupons;
        })
    };

    const exportCoupons = () => {
        const token = localStorage.getItem("token");
        window.open("/api/export/coupons?token=" + token);
    };

    const handlePageClick = async data => {
        const selected = data.selected;
        let skip = Math.ceil(selected * 10);
        await getCoupons(skip);
    };

    const handleSortChange = async event => {
        const {value} = event.target;
        setSort(value);
        if (value === 'city') {
            setIsSortCity(true);
        } else {
            setIsSortCity(false);
            await handleSortSubmit(0);
        }
    };

    const handleSortSubmit = async (skip, city) => {
        await getCoupons(skip, city);
    };


    return (
        <div className="page">
            <h1 className="page__title">Kuponer</h1>
            <div className="mb-1">
                <Button className="btn--primary mr-1"
                        onClick={() => setCreateModalOpen(true)}>
                    Opret Kupon
                </Button>
                <Button className="btn--primary mr-1"
                        onClick={exportCoupons}>
                    Eksportér Til CSV
                </Button>
                <Button className="btn--primary mr-1" onClick={() => setPushModalOpen(true)}>
                    Opret Pushbesked
                </Button>
            </div>
            <div className="mb-1">
                Total Antal Indløste Kuponer: {redeemedCount}
            </div>
            <div className="mb-1">
                <h3>Sortér</h3>
                <section className="flex">
                    <select className="form__input mr-1 col--3" value={sort} onChange={e => handleSortChange(e)}>
                        <option value="name">Navn</option>
                        <option value="city">By</option>
                    </select>
                    {isSortCity && (
                        <Autocomplete url='/api/admin/cities'
                                      label="name"
                                      placeholder="Søg efter by"
                                      searchTerm={citySearchTerm}
                                      setSearchTerm={setSitySearchTerm}
                                      onItemSelected={item => handleSortSubmit(0, item)}/>
                    )}
                </section>
            </div>
            <table className="table mb-1">
                <thead>
                <tr>
                    <td style={{width: "50%"}}>Titel</td>
                    <td style={{width: "10%"}}>Er Aktiv?</td>
                    <td style={{width: "10%"}} className="td-only-desktop">Udløste</td>
                    <td style={{width: "10%"}} className="td-only-desktop">Favoritter</td>
                    <td style={{width: "20%"}} className="td-only-desktop">Handlinger</td>
                </tr>
                </thead>
                <tbody>
                {coupons.map((coupon, index) => (
                    <tr key={index}>
                        <td>{coupon.title}</td>
                        <td>{coupon.active ? "Ja" : "Nej"}</td>
                        <td className="td-only-desktop">{coupon.redeemedCount}</td>
                        <td className="td-only-desktop">{coupon.favoriteCount}</td>
                        <td className="td-only-desktop">
                            {coupon.active
                                ? <Button className="btn--primary btn--small mr-1"
                                          onClick={() => deactivateCoupon(coupon._id, index)}>
                                    <i className="fas fa-times"/>
                                </Button>
                                : <Button className="btn--primary btn--small mr-1"
                                          onClick={() => activateCoupon(coupon._id, index)}>
                                    <i className="fas fa-check"/>
                                </Button>
                            }
                            <Button className="btn--primary btn--small mr-1"
                                    onClick={() => selectCouponForEdit(coupon)}>
                                <i className="fas fa-edit"/></Button>
                            <Button className="btn--primary btn--small" onClick={() => deleteCoupon(coupon._id, index)}>
                                <i className="fas fa-trash"/>
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
                previousLabel="Forrige"
                nextLabel="Næste"
            />
            {activeCoupon &&
            <EditCoupon isOpen={isEditModalOpen} onClose={closeEditModal} setCoupons={setCoupons}
                        coupon={activeCoupon}/>}
            <CreateCoupon isOpen={isCreateModalOpen} onClose={() => setCreateModalOpen(false)} setCoupons={setCoupons}/>
            <Push isOpen={isPushModalOpen} onClose={() => setPushModalOpen(false)}/>
        </div>
    )
}
