import React, {useContext, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "./Navbar.css";
import AppContext from "../../Contexts/AppContext";
import ShareModal from "../ShareModal/ShareModal";
import ActiveCityModal from "../ActiveCityModal/ActiveCityModal";
import Logo from "../../Images/Logo.png";

const Navbar = ({isAdmin}) => {
    const history = useHistory();
    const {user, logout} = useContext(AppContext);
    const [isShareModalOpen, setShareModalOpen] = useState(false);
    const [isCityModalOpen, setCityModalOpen] = useState(false);
    const [isNavOpen, setNavOpen] = useState(false);

    const openNav = () => {
        setNavOpen(true);
    };

    const closeNav = () => {
        setNavOpen(false);
    };

    const handleLogout = () => {
        logout();
        history.push("/auth/login");
        closeNav();
    };

    return (
        <nav className="navbar flex--column">
            <div className="navbar__top">
                <a href="#" onClick={openNav} className="navbar__link">
                    Menu <i className="fas fa-bars"/>
                </a>
                <div className="navbar__logo">
                    <img src={Logo} alt="logo" className="navbar__logo-img"/>
                </div>
                <a href="#" onClick={() => setCityModalOpen(true)}
                   className="navbar__link navbar__link--border-red">
                    <i className="fas fa-street-view"></i> {user.activeCity ? user.activeCity.name : 'Skift By'}
                </a>
            </div>
            <div className="navbar_bottom">
                {isAdmin ? (
                    <ul className="navbar__list">
                        <li className="navbar__list-item">
                            <Link to="/admin"
                                  className="navbar__link navbar__link--border-black">
                                Kuponer
                            </Link>
                        </li>
                        <li className="navbar__list-item">
                            <Link to="/admin/cities"
                                  className="navbar__link navbar__link--border-black">
                                Byer
                            </Link>
                        </li>
                        <li className="navbar__list-item">
                            <Link to="/admin/users"
                                  className="navbar__link navbar__link--border-black">
                                Brugere
                            </Link>
                        </li>
                        <li className="navbar__list-item">
                            <Link to="/"
                                  className="navbar__link navbar__link--border-black">
                                Webside
                            </Link>
                        </li>
                    </ul>
                ) : (
                    <ul className="navbar__list">
                        <li className="navbar__list-item">
                            <Link to="/"
                                  className="navbar__link navbar__link--border-black">
                                Alle
                            </Link>
                        </li>
                        <li className="navbar__list-item">
                            <Link to="/favorites"
                                  className="navbar__link navbar__link--border-black">
                                Favoritter
                            </Link>
                        </li>
                        <li className="navbar__list-item">
                            <a href="#"
                               onClick={() => setShareModalOpen(true)}
                               className="navbar__link navbar__link--border-black">
                                Del Appen
                            </a>
                        </li>

                    </ul>
                )}


            </div>

            <div className={`overlay ${isNavOpen ? 'overlay--active' : ''}`}>
                <a href="#" className="closebtn" onClick={closeNav}>&times;</a>
                <div className="overlay-content">
                    <Link to="/profile" onClick={closeNav}><i className="fas fa-user"></i> Profil</Link>
                    {user && user.role === 'admin' && <Link to="/admin" onClick={closeNav}>Admin</Link>}
                    <a href="#" onClick={handleLogout}><i className="fas fa-sign-out-alt"></i> Log Ud</a>
                </div>
            </div>

            <ShareModal isOpen={isShareModalOpen} onClose={() => setShareModalOpen(false)}/>
            <ActiveCityModal isOpen={isCityModalOpen} onClose={() => setCityModalOpen(false)}/>
        </nav>
    );
};

export default Navbar;
