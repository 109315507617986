import React, {useState, useContext, useEffect} from "react";
import AppContext from "../../../Contexts/AppContext";
import "./ChangePassword.css";
import Modal from "../../../Components/Modal/Modal";
import FormErrors from "../../../Components/FormErrors/FormErrors";
import HttpClient from "../../../Services/HttpClient";
import Button from "../../../Components/Button/Button";

export default function ChangePassword(props) {
    const {user} = useContext(AppContext);
    const [isModalOpen, setModalOpen] = useState(false);
    const [errors, setErrors] = useState([]);
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");

    const onSubmit = async e => {
        e.preventDefault();
        let _errors = [];
        setErrors([]);

        if (!currentPassword) _errors.push("Nuværende kodeord er påkrævet");
        if (!password) _errors.push("Nyt kodeord er påkrævet");
        if (!passwordAgain) _errors.push("Nyt kodeord bekræftelse er påkrævet");
        else if (password !== passwordAgain) _errors.push("Kodeord skal være ens");

        if (_errors.length) {
            setErrors(_errors);
            return;
        }

        try {
            const data = {
                password,
                currentPassword
            };

            await HttpClient().post("/api/user/change-password", data);
            onClose();
            setCurrentPassword("");
            setPassword("");
            setPasswordAgain("");
        } catch (e) {
            setErrors([e.response.data.message]);
        }
    };

    useEffect(() => {
        setModalOpen(props.isOpen);
    }, [props.isOpen]);

    const onClose = () => {
        props.onClose();
    };

    return (
        <div className="change-password">
            <Modal isOpen={isModalOpen}
                   onClose={onClose}>
                <h2 className="change-password__title">Change Password</h2>
                <hr className="line"/>
                <form className="form" onSubmit={onSubmit}>
                    {!!errors.length && <FormErrors errors={errors}/>}
                    <div className="form__group mb-1">
                        <label className="form__label">Nuværende Kodeord</label>
                        <input className="form__input"
                               onChange={e => setCurrentPassword(e.target.value)}
                               type="password"
                               value={currentPassword}/>
                    </div>
                    <div className="form__group mb-1">
                        <label className="form__label">Nyt Kodeord</label>
                        <input className="form__input"
                               onChange={e => setPassword(e.target.value)}
                               type="password"
                               value={password}/>
                    </div>
                    <div className="form__group mb-1">
                        <label className="form__label">Nyt Kodeord Igen</label>
                        <input className="form__input"
                               type="password"
                               onChange={e => setPasswordAgain(e.target.value)}
                               value={passwordAgain}/>
                    </div>


                    <Button className="btn--success" type="submit">Skift</Button>
                </form>
            </Modal>
        </div>
    )
}
