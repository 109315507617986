import React, {useContext, useEffect, useState} from "react";
import Modal from "../../../../Components/Modal/Modal";
import FormErrors from "../../../../Components/FormErrors/FormErrors";
import Button from "../../../../Components/Button/Button";
import AppContext from "../../../../Contexts/AppContext";
import Autocomplete from "../../../../Components/Autocomplete/Autocomplete";
import Alert from "../../../../Components/Alert/Alert";
import HttpClient from "../../../../Services/HttpClient";

export default function ({onClose, isOpen, setCities, city}) {
    const {loading, setLoading} = useContext(AppContext);
    const [errors, setErrors] = useState([]);
    const [name, setName] = useState(city.name);
    const [active, setActive] = useState(city.active ? 1 : 0);

    const onSubmit = async event => {
        event.preventDefault();
        setErrors([]);

        if (!name) return setErrors(["Navn er påkrævet"]);

        try {
            const data = {
                active,
                id: city._id
            };

            if (name !== city.name) {
                data.name = name;
            }

            await HttpClient().post('/api/admin/cities/update', data);
            setCities(prevState => {
                const _cities = [...prevState];
                const index = _cities.findIndex(c => c._id === city._id);
                _cities[index].name = name;
                _cities[index].active = active;
                return _cities;
            });
            onClose();

        } catch (e) {
            setErrors([e.response.data.message]);
        }

    };

    return (
        <Modal onClose={onClose} isOpen={isOpen}>
            <h1 className="mb-1">Redigér By</h1>
            <form onSubmit={onSubmit}>
                {!!errors.length && <FormErrors errors={errors}/>}
                <div className="form__group mb-1">
                    <label className="form__label">Navn</label>
                    <input className="form__input" value={name} onChange={e => setName(e.target.value)}/>
                </div>
                <div className="form__group mb-1">
                    <label className="form__label">Er byen aktiv?</label>
                    <select value={active} onChange={e => setActive(parseInt(e.target.value))}>
                        <option value={1}>Ja</option>
                        <option value={0}>Nej</option>
                    </select>
                </div>
                <Button className="btn--primary"
                        type="submit"
                        loading={loading}
                        disabled={loading}>Gem</Button>
            </form>
        </Modal>
    )
}
